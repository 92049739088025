import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { IPropsSeo } from './models';

const Seo: FC<IPropsSeo> = ({
  siteSettings,
  brandSettings,
  lang,
  seo: { seoMetaKeywords, seoMetaTitle, seoMetaDescription, seoExternalHreflangs, ogImage },
}) => {
  const siteTitle = siteSettings.siteName || '';
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const siteDir = siteSettings.dir[0];
  const link =
    seoExternalHreflangs?.find((item) => item.key.toLowerCase() === 'x-default')?.value || '';

  const ogImageUrl =
    seoExternalHreflangs?.find((item) => item.key.toLowerCase() === 'ogimage')?.value || '';

  const externalHreflangs = seoExternalHreflangs?.filter((item) => item.key !== 'ogImage');

  const meta = [
    {
      name: 'robots',
      content:
        process.env.GATSBY_SEO_ROBOTS_PERMISSION === '1' ? 'index, follow' : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: seoMetaDescription,
    },
    {
      property: 'og:title',
      content: seoMetaTitle,
    },
    {
      property: 'og:description',
      content: seoMetaDescription,
    },
    {
      property: 'og:url',
      content: link,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    ...(ogImage
      ? [
          {
            name: 'og:image',
            content: ogImage,
          },
        ]
      : ogImageUrl
      ? [
          {
            name: 'og:image',
            content: ogImageUrl,
          },
        ]
      : [
          {
            name: 'og:image',
            content:
              siteSettings.siteUrl + brandSettings.brandLogo.gatsbyImage.childImageSharp.fluid.src,
          },
        ]),

    ...(seoMetaKeywords?.length
      ? [
          {
            name: 'keywords',
            content: seoMetaKeywords.join(', '),
          },
        ]
      : []),
  ];

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang, dir: siteDir }}
      meta={meta}
      title={seoMetaTitle || siteTitle}
    >
      {externalHreflangs?.map(({ key, value }) => (
        <link rel="alternate" href={value} hrefLang={key} />
      ))}
    </Helmet>
  );
};

export default Seo;
